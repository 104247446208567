@media (min-width: 1024px){.hidden-from-md {
  display: none;
}

.hidden.display-to-md {
  display: inherit !important;
}

.margin-bottom {
  margin-bottom: 4em;
}

:root {
  --container-width: 100%;
  --container-padding: 40px;
}

.a2a_kit {
  bottom: 160px;
  margin-right: 12px;
}

.addtoany_share .a2a_svg {
  width: 60px !important;
  height: 60px !important;
  line-height: 60px !important;
}

.addtoany_share .a2a_svg::after {
  font-size: 60px;
}

.nav-primary .ico {
  font-size: 60px;
}

.nav-primary a {
  font-size: 18px;
}

.col-md-2 {
  --grid-col: 2;
}

.col-md-3 {
  --grid-col: 3;
}

.col-md-4 {
  --grid-col: 4;
}

.col-md-5 {
  --grid-col: 5;
}

.col-md-6 {
  --grid-col: 6;
}

.col-md-7 {
  --grid-col: 7;
}

.col-md-8 {
  --grid-col: 8;
}

.col-md-9 {
  --grid-col: 9;
}

.col-md-12 {
  --grid-col: 12;
}

.list_of_thematiques {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 12px));
  grid-gap: 12px 24px;
}

.open_acid {
  --sizeIco: 60px;
}

.cancel_acid {
  font-size: 60px;
  margin-right: 16px;
  bottom: 230px;
}}@media (min-width: 1280px){:root {
  --borderWidth: 2px;
}

.hidden-from-lg {
  display: none;
}

.hidden.display-to-lg {
  display: inherit !important;
}

:root {
  --container-padding: 70px;
  --header-height: 135px;
  --header-padding: 40px;
}

.a2a_kit {
  bottom: 50px;
  margin-right: 0;
}

.addtoany_share .a2a_svg {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}

.addtoany_share .a2a_svg::after {
  font-size: 80px;
}

#banner {
  padding-top: var(--header-padding);
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
}

#banner .container {
  grid-template-columns: 260px 2fr 1fr;
  align-items: center;
  gap: 24px;
}

#banner .logo {
  font-size: 64px;
}

#banner a {
  font-size: 18px;
}

.nav-primary::before,
.nav-primary::after {
  top: 0px;
  height: 170px;
  -webkit-mask-image: linear-gradient(to bottom, #000000 85%, transparent 100%);
  mask-image: linear-gradient(to bottom, #000000 85%, transparent 100%);
  background-size: 100% 170vh;
  background-position-y: 0;
  min-height: 0;
  animation: movebg2 var(--animation-background-position) ease infinite;
}

.nav-primary .cnav>ul {
  grid-template-columns: repeat(6, max-content);
}

.fake-breadcrumbs span {
  font-size: 32px;
}

.button,
.tnp-field .tnp-submit {
  --minWidthButton: 170px;
  --heightButton: 60px;
  --fontSizeButton: 24px;
  --borderButton: 1.5px;
  --sizeIconButton: 90px;
}

.button:hover,
.tnp-field .tnp-submit:hover {
  background-color: var(--backgroundColorHover);
  color: var(--colorHover);
}

.button:hover .ico,
.tnp-field .tnp-submit:hover .ico {
  color: var(--backgroundColorHover);
}

.button.with_ico,
.tnp-field .with_ico.tnp-submit {
  --sizeGapButton: 12px;
}

.button.grow-up,
.tnp-field .grow-up.tnp-submit {
  --fontSizeButton: 30px;
}

.button.small,
.tnp-field .small.tnp-submit {
  --minWidthButton: 100px;
  --heightButton: 40px;
  --fontSizeButton: 15px;
  --borderButton: 1px;
  --sizeIconButton: 60px;
}

.button.small.grow-up,
.tnp-field .small.grow-up.tnp-submit {
  --minWidthButton: 100px;
  --fontSizeButton: 20px;
}

.button.large,
.tnp-field .large.tnp-submit,
.button.large-md,
.tnp-field .large-md.tnp-submit {
  --minWidthButton: 420px;
  --heightButton: 120px;
  --fontSizeButton: 50px;
  --borderButton: 2px;
  --sizeIconButton: 180px;
}

.button.large.grow-up,
.tnp-field .large.grow-up.tnp-submit,
.button.large-md.grow-up,
.tnp-field .large-md.grow-up.tnp-submit {
  --minWidthButton: 420px;
  --fontSizeButton: 60px;
}

.button.ico_only:hover,
.tnp-field .ico_only.tnp-submit:hover {
  color: var(--colorHover);
  background-color: var(--backgroundColorHover);
}

.button.ico_only:hover .ico,
.tnp-field .ico_only.tnp-submit:hover .ico {
  color: var(--colorHover);
}

.button.full_mobile,
.tnp-field .full_mobile.tnp-submit {
  display: inline-flex;
  margin-bottom: .5em;
}

.secondary:hover,
.tnp-field .tnp-submit:hover {
  mix-blend-mode: var(--mix-blend-mode);
}

.button:hover .ico[data-ico]:before,
.tnp-field .tnp-submit:hover .ico[data-ico]:before,
a:hover .ico[data-ico]:before,
.link:hover .ico[data-ico]:before,
label:hover .ico[data-ico]:before {
  content: attr(data-ico) "full";
}

.button:hover .ico[data-icofull]:before,
.tnp-field .tnp-submit:hover .ico[data-icofull]:before,
a:hover .ico[data-icofull]:before,
.link:hover .ico[data-icofull]:before,
label:hover .ico[data-icofull]:before {
  content: attr(data-icofull);
}

.card .excerpt {
  display: block;
  font-size: 24px;
}

.card .full-link:hover~.card-detail .secondary,
.card .full-link:hover~.card-detail .tnp-field .tnp-submit,
.tnp-field .card .full-link:hover~.card-detail .tnp-submit {
  --color: var(--backgroundColorTheme);
  --backgroundColor: var(--colorTheme);
  --colorHover: var(--colorTheme);
  --backgroundColorHover: transparent;
  mix-blend-mode: var(--mix-blend-mode);
}

.card .card-info .button,
.card .card-info .tnp-field .tnp-submit,
.tnp-field .card .card-info .tnp-submit {
  margin-right: 18px;
}

.card-contact {
  margin-left: 70px;
  margin-bottom: 40px;
}

.card-contact .card-denom {
  position: relative;
}

.card-contact .card-denom:before {
  content: "";
  position: absolute;
  top: 0;
  left: -70px;
  width: 42px;
  height: 42px;
  background-image: url(../../assets/lib/leaflet/marker.svg);
  background-size: contain;
}

.faq-title {
  --padding: 28px;
}

#footer {
  margin-top: 200px;
  padding-bottom: 40px;
  position: relative;
}

#footer .grid-content {
  padding-top: 40px;
  border-top: 1px solid var(--colorTheme);
  display: grid;
  grid-template-columns: 200px auto 350px 36%;
  grid-template-rows: repeat(3, auto);
  grid-gap: 32px;
}

#footer .logo {
  font-size: 200px;
  grid-column: 1/2;
  grid-row: 1/3;
}

#footer address {
  grid-column: 2/4;
}

#footer #nav-secondary {
  grid-column: 4/5;
  grid-row: 1/3;
}

#footer #nav-secondary ul {
  column-count: 2;
}

#footer .nav-rs {
  grid-column: 2/4;
  grid-row: 2/3;
}

#footer .nav-tertiary {
  grid-column: 1/5;
}

#footer .nav-tertiary li {
  display: inline-block;
  margin-right: 28px;
}

input {
  --inputFontSize: 24px;
  --inputHeight: 60px;
}

.checkbox {
  display: inline-block;
  margin-right: 1em;
}

.search-form .search-button {
  top: 18px;
}

#map {
  position: sticky;
  top: 200px;
  height: 70vh;
}

#map,
#annuaire_list {
  margin-top: 40px;
}

#leaflet-map {
  max-height: 70vh;
  border-radius: 24px;
  border: 1px solid var(--colorTheme);
}

.leaflet-control-container .leaflet-top.leaflet-left {
  right: 14px;
  bottom: 24px;
}

#pwa-install {
  display: none;
}

.slider-section {
  margin-top: 48px;
  margin-bottom: 96px;
}

.swipe-container .btn-swipe.right {
  right: 48px;
}

.swipe-container .btn-swipe.left {
  left: 48px;
}

:root {
  --default-font-size: 30px;
  line-height: 37px;
}

h1,
.h1 {
  font-size: 80px;
  line-height: 80px;
}

h2,
.h2 {
  font-size: 60px;
  line-height: 65px;
}

h3,
.h3,
.page-title,
.article_title {
  font-size: 45px;
  line-height: 55px;
}

h4,
.h4 {
  font-size: 35px;
  line-height: 42px;
}

h5,
.h5 {
  font-size: 30px;
  line-height: 37px;
}

h6,
.h6 {
  font-size: 24px;
  line-height: 28px;
}

.h7 {
  font-size: 15px;
  line-height: 18px;
}

body.variant-background::after,
body.variant-background::before {
  background-size: 100% 170vh;
  animation: movebg2 var(--animation-background-position) ease infinite;
}

@-webkit-keyframes movebg2 {
  0% {
    background-position: 0% 0vh;
  }

  50% {
    background-position: 0% -70vh;
  }

  100% {
    background-position: 0% 0vh;
  }
}

@-moz-keyframes movebg2 {
  0% {
    background-position: 0% 0vh;
  }

  50% {
    background-position: 0% -70vh;
  }

  100% {
    background-position: 0% 0vh;
  }
}

@keyframes movebg2 {
  0% {
    background-position: 0% 0vh;
  }

  50% {
    background-position: 0% -70vh;
  }

  100% {
    background-position: 0% 0vh;
  }
}

main {
  margin-top: var(--header-height);
}

.grid {
  --grid-gap: 30px;
}

.col-lg-2 {
  --grid-col: 2;
}

.col-lg-3 {
  --grid-col: 3;
}

.col-lg-4 {
  --grid-col: 4;
}

.col-lg-5 {
  --grid-col: 5;
}

.col-lg-6 {
  --grid-col: 6;
}

.col-lg-7 {
  --grid-col: 7;
}

.col-lg-8 {
  --grid-col: 8;
}

.col-lg-9 {
  --grid-col: 9;
}

.col-lg-12 {
  --grid-col: 12;
}

.lexique_list .link,
.lexique_list strong {
  padding: 20px 0;
  border-bottom-width: 2px;
}

#thematique_form label {
  margin-bottom: 4.5rem;
}

.list_of_thematiques {
  margin: 80px auto 0;
}

.list_of_posts-section {
  --first-post-height: 360px;
  --image-width: 480px;
  --image-height: 270px;
}

.list_of_posts-section .with_first_post {
  padding-top: var(--first-post-height);
}

.list_of_posts-section .with_first_post .btn-swipe {
  transform: translateY(calc(170px + var(--first-post-height)));
}

.list_of_posts-section .first_post {
  position: absolute;
  top: 0;
  left: var(--container-padding);
  right: var(--container-padding);
}

.list_of_posts-section .first_post figure {
  padding-top: 0;
  width: var(--image-width);
  height: var(--image-height);
  margin-right: 28px;
  margin-bottom: 0;
}

.list_of_posts-section .first_post figure,
.list_of_posts-section .first_post .card-detail {
  display: inline-block;
}

.list_of_posts-section .first_post .card-detail {
  vertical-align: top;
  width: calc(100% - var(--image-width) - 36px);
}

.list_hub {
  --padding: 15px;
  --font-size: 34px;
  --font-size-ico: 80px;
  --font-size-triangle: 30px;
}

.infos {
  display: flex;
}

.slash {
  display: block;
  margin: 0 2em;
}

.col-2 {
  column-count: 2;
}

.col-2 .page_item_has_children {
  break-inside: avoid;
}

.illu-404 .ico {
  font-size: 15rem;
}

.with-ico-page {
  --sizeIco: 10rem;
  line-height: var(--sizeIco);
  top: 0;
  bottom: auto;
}

.open_acid {
  --sizeIco: 80px;
  animation: openacide 100s linear infinite;
}

@keyframes openacide {
  0% {
    transform: translate(calc(var(--sizeIco) * -1), 60vh);
  }

  70% {
    transform: translate(calc(var(--sizeIco) * -1), 60vh);
  }

  73.75% {
    transform: translate(40vw, 0vh);
  }

  77.5% {
    transform: translate(calc(100vw - (var(--sizeIco))), 40vh);
  }

  81.25% {
    transform: translate(60vw, calc(100vh - (var(--sizeIco))));
  }

  85% {
    transform: translate(0, 40vh);
  }

  88.75% {
    transform: translate(60vw, 0vh);
  }

  92.5% {
    transform: translate(calc(100vw - (var(--sizeIco))), 60vh);
  }

  96.25% {
    transform: translate(40vw, calc(100vh - (var(--sizeIco))));
  }

  100% {
    transform: translate(calc(var(--sizeIco) * -1), 60vh);
  }
}

.cancel_acid {
  font-size: 80px;
  margin-right: 0;
  bottom: 150px;
}}@media (min-width: 1440px){.hidden-from-xl {
  display: none;
}

.hidden.display-to-xl {
  display: inherit !important;
}

:root {
  --header-height: 200px;
  --header-padding: 60px;
}

#banner .container {
  grid-template-columns: 420px 2fr 1fr;
  gap: 40px;
}

#banner .logo {
  font-size: 104px;
}

#banner a {
  font-size: 21px;
}

.nav-primary {
  max-width: 730px;
  max-height: 105px;
}

.nav-primary .ico {
  font-size: 80px;
}

.nav-primary::before,
.nav-primary::after {
  height: 215px;
}

.swipe-container .btn-swipe {
  transform: translateY(230px);
}

#wrapper {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

#wrapper,
.container {
  max-width: 1920px;
}

.list_of_posts-section {
  --first-post-height: 500px;
  --image-width: 650px;
  --image-height: 435px;
}

.list_of_posts-section .with_first_post .btn-swipe {
  transform: translateY(calc(230px + var(--first-post-height)));
}}@media (min-width: 1440px)and (min-width: 1900px){#footer .grid-content {
  grid-template-columns: 200px auto 350px 57.5%;
}}